.cardTextStyle {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}

.navTextStyle {
  color: #999;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.footerTextStyle {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
}

.searchTextStyle {
  color: #636363;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.titleStyle {
  color: #fff;
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 2.68px;
}

.buttonPrimaryStyle {
  width: 125px;
  height: 38px;
}

.buttonNavStyle {
  width: 147px;
  height: 38px;
}

.navStyle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeStyle {
  background-image: url(/public-home.png);
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.footerStyle {
  text-align: center;
  background: #000 !important;
}

.titleContainerStyle {
  margin-top: 96px;
}

.dateTrackingFirst {
  font-size: 16px;
  line-height: 22px;
}

.dateTrackingLast {
  font-size: 16px;
  line-height: 22px;
  color: #999999;
}

@media only screen and (max-width: 600px) {
  .navTextStyle {
    display: none;
  }

  .navStyle {
    display: none;
  }

  .homeStyle {
    background-image: url(/public-home-mobile.svg);
    height: 265px;
  }

  .footerStyle {
    display: none;
  }

  .titleStyle {
    color: #fff;
    font-size: 25px;
    font-weight: 900;
    line-height: 33px;
    letter-spacing: 2.68px;
  }

  .titleContainerStyle {
    margin-top: 51px;
    margin-bottom: 45px;
    text-align: center;
  }
}
